<template>
  <!-- 投票环节页面 -->
  <div class="resourceuploadbrowsing study-active-introduce">
    <ActivityLinkInfo
      :activityInfoData="activityInfoData"
      :infoData="resourceData"
    ></ActivityLinkInfo>
    <!-- <div class="hengxian"></div> -->
    <div class="main_content">
      <div class="title-box">投票</div>
      <!-- <div class="five cursor" v-if="textActive == true" @click="showText">
        <span>查看全部</span>
        <i class="el-icon-arrow-down"></i>
      </div>
      <div class="five cursor" v-else-if="textActive == false" @click="showText">
        <span>收起</span>
        <i class="el-icon-arrow-up"></i>
      </div> -->

      <template v-if="subjectShow && resourceData.implement_type == 1">
        <Subject
          :data="resourceData"
          appName="投票"
          :is_from="+is_from"
          @onSubmit="onSubmit"
          v-if="
            resourceData.status != 1 ||
            resourceData.user_id == userId ||
            resourceData.teaching_group_user_id == userId
          "
        ></Subject>
        <p class="noData" v-else>该活动暂未开始</p>
      </template>
      <!-- <div v-if="resourceData.implement_type == '1'">
        <div class="six">
          <p>投票</p>
          <p>投票截止时间：{{ resourceData.end_date }}</p>
        </div>
        <div class="seven">
          <div
            class="vote_block"
            v-for="(item, index) in resourceData.questionnaire_data"
            :key="index"
          >
            <p class="vote_title" v-html="item.title"></p>
            <div class="option" v-if="item.type == '1'">
              <el-radio
                v-model="item.selectedId"
                :label="item2.id"
                class="space"
                v-for="(item2, index2) in item.options"
                :key="index2"
                >{{ item2.content }}

                <div
                  class="progress_yangshi"
                  v-if="resourceData.finish_status == true"
                >
                  <div>
                    <el-progress
                      :text-inside="true"
                      :stroke-width="26"
                      :percentage="Number(item2.proportion)"
                    ></el-progress>
                  </div>
                  <span
                    style="margin-left: 30px"
                    v-if="resourceData.finish_status == true"
                    class="person"
                    >投票人数：{{ item2.count }}人
                  </span>
                </div>
              </el-radio>
            </div>
            <div v-else class="multiple">
              <el-checkbox-group v-model="item.selectedId">
                <div
                  v-for="(item3, index3) in item.options"
                  :key="index3"
                  class="new_option"
                >
                  <el-checkbox :label="item3.id">{{
                    item3.content
                  }}</el-checkbox>
                  <div
                    class="progress_yangshi"
                    v-if="resourceData.finish_status == true"
                  >
                    <div>
                      <el-progress
                        :text-inside="true"
                        :stroke-width="26"
                        :percentage="Number(item3.proportion)"
                      ></el-progress>
                    </div>
                    
                    <span
                      style="margin-left: 30px"
                      
                      class="person"
                      >投票人数：{{ item3.count }}人
                    </span>
                  </div>
                </div>
              </el-checkbox-group>
            </div>
          </div>
        </div>
        <div class="eight">
          <div
            class="save cursor"
            @click="submit"
            v-if="resourceData.finish_status == false"
          >
            投票
          </div>
        </div>
      </div> -->

      <!-- 线下区域 -->
      <UnderLineDetail
        :is_from="+is_from"
        :resourceData="resourceData"
        v-else-if="resourceData.implement_type == 2"
        @getResourceData="getResourceData"
        :activityId="+activityId"
        :linkId="+id"
      />
    </div>
    <!-- 结束文案对话框 -->
    <el-dialog :visible.sync="writeVisible" width="25%" :show-close="false">
      <div class="copy_write">
        <div class="write_one">
          <!-- <span>{{ copywriting }}</span> -->
          <span>{{ endText }}</span>
        </div>
        <div class="write_two">
          <el-button type="primary" @click="closecopy">关闭</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Subject from "../components/subject/subject.vue";
import UnderLineDetail from "../components/underlineDetail/index.vue";
import { ActiveHjDetail } from "@/api/teacherStudio";
import * as researchApi from "@/api/researchApi.js";
import ActivityLinkInfo from "../components/activityLinkInfo.vue";
import { queryString } from "@/utils/utils.js";
export default {
  components: {
    Subject,
    UnderLineDetail,
    ActivityLinkInfo,
  },
  props: {
    //活动信息数据
    activityInfoData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // 结束文案
      endText: "",
      // 活动Id
      activityId: "",
      // 环节id
      id: "",
      // 资源浏览数据
      resourceData: {},
      // 当前登录的用户名
      username: "",
      // 当前登录的用户id
      userId: "",

      //线下表单
      xianxiaform: {
        name: "",
        accessoryId: "",
        url: "",
      },
      // 文本是否隐藏
      textActive: true,
      // 文案数据
      copywriting: "",
      // 控制文案数据显示与否
      writeVisible: false,
      subjectShow: false, //是否显示题目组件
      // 1教研活动详情，2四名工作室活动详情
      is_from: 1,
    };
  },
  created() {
    this.init();
  },
  methods: {
    // 初始化函数
    init() {
      this.activityId = this.$route.query.activityId;
      this.id = this.$route.query.id;
      this.is_from = this.$route.query.is_from || 1;
      this.username = window.localStorage.getItem("username");
      this.userId = window.localStorage.getItem("id");
      this.getResourceData();
    },
    //请求后台获取资源浏览数据
    getResourceData() {
      if (this.is_from == 1) {
        //教研组
        this.$axios
          .get(`activity/${this.activityId}/activityLink/${this.id}`)
          .then((res) => {
            this.resourceData = res.data.data;
            //文案
            this.endText = this.resourceData.questionnaire_data.submit_doc;
            this._questionnaire();
          });
      } else {
        //四名工作室
        ActiveHjDetail(this.activityId, this.id).then((res) => {
          this.resourceData = res.data;
          this._questionnaire();
          //   this.resourceData.status = "2"
        });
      }
    },
    // 文本显示与隐藏函数
    showText() {
      if (this.textActive == true) {
        this.textActive = false;
      } else if (this.textActive == false) {
        this.textActive = true;
      }
    },
    // 问卷结束文案接口
    _questionnaire() {
      this.$axios.get("default/questionnaire").then((res) => {
        this.copywriting = res.data.data.closings_txt;
        Object.assign(this.resourceData, { closingstxt: this.copywriting });
        this.subjectShow = true;
      });
    },
    // 点击关闭文案对话框进行的函数
    closecopy() {
      this.writeVisible = false;
    },
    //投票提交回调
    onSubmit() {
      this.getResourceData();
    },
  },
};
</script>

<style scoped lang="less">
.resourceuploadbrowsing {
  .hengxian {
    width: 100%;
    height: 1px;
    background: #ececec;
  }
  .main_content {
    .option_box_div {
      margin-left: auto;
      font-size: 16px;
      color: #333333;
      /deep/.el-input__inner {
        background-color: #f4fbff;
      }
    }
    .five {
      color: #3489fe;
      margin-bottom: 36px;
      span {
        margin-right: 11px;
      }
    }
    .six {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      p:nth-child(1) {
        font-size: 18px;
        font-weight: 700;
        color: #1a1a1a;
        margin-right: 19px;
      }
      p:nth-child(2) {
        color: #ff8201;
      }
    }
    .seven {
      .vote_block {
        margin-bottom: 10px;
        .vote_title {
          font-weight: 700;
          color: #1a1a1a;
          margin-bottom: 28px;
        }
        .option {
          display: flex;
          flex-direction: column;
          // margin-left: 20px;
          width: 930px;
          flex-wrap: wrap;

          .space {
            margin-bottom: 28px;
          }
        }
      }
    }
    .eight {
      display: flex;
      justify-content: center;
      .save {
        width: 148px;
        height: 42px;
        background: #ff8201;
        border-radius: 6px;
        font-weight: 700;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .progress_yangshi {
    position: relative;
    width: 393px;
    margin-top: 20px;
    margin-left: 25px;
    .person {
      position: absolute;
      top: 6px;
      right: -110px;
    }
  }
}
.new_option {
  width: 955px;
  margin-bottom: 28px;
  .el-checkbox,
  .el-checkbox__input {
    white-space: normal;
  }
  /deep/ .el-checkbox__label {
    display: inline;
    line-height: 30px;
  }
  //   /deep/ .el-checkbox__input {
  // 	display: inline-grid;
  //     white-space: pre-line;
  //     word-wrap: break-word;
  //     overflow: hidden;
  //     line-height: 20px;
  //  }
}
.multiple {
  .el-checkbox-group {
    font-size: 14px;
  }
}
.option {
  .el-radio,
  .el-radio__input {
    white-space: normal;
    line-height: 20px;
  }
}
.copy_write {
  .write_one {
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 30px;
  }
  .write_two {
    display: flex;
    flex-direction: row-reverse;
  }
}

.noData {
  width: 100%;
  height: 100px;
  text-align: center;
  line-height: 100px;
  font-size: 15px;
}
</style>
